const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/shop-by-brand';

const InitFunc = () => {
  if (window.document.querySelector('#cm_results, #cm-brand-list')) {
    const unwantedPageWrappers = [
      ['.container .page__content.reading-width.rte', ['reading-width', 'rte', 'article-content']],
    ];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(...className);
    });
  }
};

const getActiveCurrency = () => globalThis.Shopify?.currency?.active;

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getActiveCurrency(),
    currencyDisplay: 'narrowSymbol',
  }).format(price);

  const lastDividerIndex = Math.max(formattedPrice.lastIndexOf('.'), formattedPrice.lastIndexOf(','));

  return `${formattedPrice.slice(0, lastDividerIndex)}<sup>${formattedPrice.slice(
    lastDividerIndex + 1,
  )}</sup>`;
}

export default {
  platform: 'shopify',
  storeId: 'functionwerks-dev',
  InitFunc,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    formatPrice,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { replace: 'header predictive-search' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 700,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'Garage',
      location: {
        selector: '#cm-garage',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: true,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
